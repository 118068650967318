.container {
  background: white;
}

.margin-top18 {
  margin-top: 18px;
}

.button-div {
  border-bottom: 1px dashed #d6d6d6;
  padding-bottom: 15px;
  width: 100%;
  margin-bottom: 15px;
}

.square {
  width: 10rem;
  margin: 1rem 0;
}
