.badge-chat .ant-badge-count {
  background-color: #f21619;
  margin: 5px;
}

.badge-chat .ant-badge-count p {
  color: #fff;
}

.badge-chat .ant-btn span {
  margin-bottom: 5px;
}
