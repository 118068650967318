.avatar {
  width: 160px !important;
  height: 160px !important;
  line-height: 160px !important;
  font-size: 80px !important;
  background-color: #22a7e7 !important;
}

.avatarsmall {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  font-size: 20px !important;
  background-color: #22a7e7 !important;
}

@media only screen and (max-device-width: 480px) {
  .avatarsmall {
    width: 55px !important;
    height: 55px !important;
    line-height: 55px !important;
    font-size: 26px !important;
  }
}
