.container {
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.containerTexts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.btn span {
  font-size: 15px !important;
}

.btn button:disabled {
  background-color: transparent !important;
  border: none !important;
}

.label {
  margin: 0 !important;
  font-size: 12px !important;
}

.status {
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 13px !important;
}

.btn {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #f54343 !important;
  border: 2px solid #f54343 !important;
  border-radius: 100% !important;
}

.btn img {
  width: 21px;
  height: 21px;
}

.btnActive {
  background-color: transparent !important;
  border: 2px solid white !important;
}

.btnEnd {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #fff !important;
  border: 2px solid #fff !important;
  border-radius: 100% !important;
}

.btnEnd img {
  width: 21px;
  height: 21px;
}

.btnMobile {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.btnEndMobile {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.containerMobile {
  display: none;
  margin-top: 15%;
}

@media only screen and (max-device-width: 540px) {
  .containerMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-color: #565656;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .container {
    display: none;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 850px) {
  .containerMobile {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 80px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
  }

  .container {
    display: none;
  }
}

.badge sup {
  box-shadow: none !important;
  margin-right: 12px !important;
  margin-top: 6px !important;
}

.recordingContainer {
  margin: 0 50px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnRecording {
  background-color: transparent !important;
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center;
  border: 1.5px solid #fff !important;
  border-radius: 100% !important;
  padding: 0 !important;
}

.btnRecording span {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  padding: 0;
  margin: 0;
}

.btnRecording svg {
  color: #f54343 !important;
}

.textRecording {
  padding: 0 10px;
  color: #fff;
}

.btnDisabled {
  border: 2px solid #bababa !important;
  background-color: #928f8b !important;
}
