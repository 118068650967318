.container {
  width: auto;
  height: auto;
  padding: 5px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-left: 5px;
  margin-top: 5px;
}

.container p {
  margin: 0;
  margin-left: 5px;
  color: #fff;
  font-size: 20px;
}

@media only screen and (max-device-width: 480px) {
  .container p {
    font-size: 15px;
  }
}
