.dropbtn {
  background-color: white;
  color: black;
  padding: 16px 16px 16px 0;
  font-size: 16px;
  border: none;
  outline: none;
}

.dropbtn:hover {
  background-color: black;
  color: black;
  padding: 16px 16px 16px 0;
  font-size: 16px;
  border: none;
  outline: none;
}

.dropbtn:focus {
  border: none;
}

.dropbtn:active {
  border: none;
}

.dropup {
  position: relative;
  display: inline-block;
}

.dropupcontent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  bottom: 50px;
  z-index: 1;
}

.dropupcontent p {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  margin-bottom: 0;
}

.dropupcontent p:hover {
  background-color: #ccc;
}

.dropup:hover .dropupcontent {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: white;
  border: none;
}
