.containerMessages {
  max-height: -webkit-calc(100vh - 120px);
  max-height: -moz-calc(100vh - 120px);
  max-height: calc(100vh - 120px);
  width: 100%;
  overflow-y: scroll;
}

@media only screen and (max-device-width: 480px) {
  .containerMessages {
    max-height: 85vh !important;
    overflow-y: auto;
  }
}
