@font-face {
  font-family: Lato;
  src: url("./styles/fonts/Lato-Regular.ttf");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Lato;
  src: url("./styles/fonts/Lato-Bold.ttf");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: LatoBold;
  src: url("./styles/fonts/Lato-Bold.ttf");
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: LatoLight;
  src: url("./styles/fonts/Lato-Light.ttf");
  font-style: normal;
  font-weight: lighter;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
li {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.date-input-custom {
  min-width: 80px;
  width: 100%;
  padding: 5px 0;
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: #676e71;
  letter-spacing: 0.6px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #c2cdd2;
  box-shadow: none;
  outline: none;
}
