@import '../../../../styles/index.scss';

$width-first: calc(100% - 380px);
$width-second: 145px;
$width-third: 180px;
$width-last: 120px;

$smallWidth-first: calc(100% - 210px);
$smallWidth-second: 50px;
$smallWidth-third: 90px;
$smallWidth-last: 70px;

$mediumWidth-first: calc(100% - 256px);
$mediumWidth-second: 140px;
$mediumWidth-third: 120px;
$mediumWidth-last: 70px;

h3 {
  @include header-h3;

  margin-bottom: 20px;
}

.text {
  @include text-regular;
}

.btnBlock {
  padding: 30px 0 20px;
  text-align: right;
}

.btnHolder {
  display: none;

  @media (min-width: 640px) {
    display: inline-block;
  }
}

.contentBlock {
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.links {
  background: none;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;

  :visited {
    text-decoration: none;
  }
}

.requestedBlock {
  display: inline-block;
  width: 270px;
  margin-right: 40px;

  @media (max-width: 700px) {
    margin: 0 0 20px 0;
    align-self: center;
  }
}

.requestedList {
  border: 1px solid rgba(0.76, 0.76, 0.76, 0.2);
}

.listRow {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 10px 25px;
  border-bottom: 1px solid rgba(0.76, 0.76, 0.76, 0.2);
}

.lastListRow {
  border-bottom: none;
}

.table {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 -20px;

  @media (min-width: 640px) {
    margin: 0;
  }
}

.checkIcon {
  cursor: pointer;
}

.header {
  @include table-header;

  align-items: center;
}

.headerCol {
  @include table-header-col;
}

.headerColFirst {
  padding: 16px 6px 16px 15px;
  width: $smallWidth-first;

  @media (min-width: 400px) {
    width: $mediumWidth-first;
  }

  @media (min-width: 640px) {
    padding: 24px 10px 22px 30px;
    width: $width-first;
  }
}

.headerColSecond {
  width: $smallWidth-second;
  padding: 16px 0;

  @media (min-width: 400px) {
    width: $mediumWidth-second;
    padding: 16px 0;
  }

  @media (min-width: 640px) {
    width: $width-second;
    padding: 24px 0 22px;
  }
}

.headerColThird {
  width: $smallWidth-third;

  @media (min-width: 400px) {
    width: $mediumWidth-third;
  }

  @media (min-width: 640px) {
    width: $width-third;
  }
}

.headerColLast {
  padding: 0;
  width: $smallWidth-last;

  @media (min-width: 400px) {
    width: $mediumWidth-last;
  }

  @media (min-width: 640px) {
    width: $width-last;
    padding: 10px 10px 30px;
  }
}

.row {
  @include table-row;
}

.cell {
  @include table-cell;
}

.cellFirst {
  width: $smallWidth-first;
  padding-left: 15px;
  font-size: 11px;
  font-weight: bold;
  color: $blue;

  @media (min-width: 400px) {
    width: $mediumWidth-first;
    font-size: 13px;
  }

  @media (min-width: 640px) {
    width: $width-first;
    padding-left: 30px;
  }
}

.cellSecond {
  width: $smallWidth-second;
  padding: 16px 0;
  color: $grey-800;

  @media (min-width: 400px) {
    width: $mediumWidth-second;
  }

  @media (min-width: 640px) {
    width: $width-second;
  }
}

.cellThird {
  width: $smallWidth-third;
  color: #7f7f7f;

  @media (min-width: 400px) {
    width: $mediumWidth-third;
  }

  @media (min-width: 640px) {
    width: $width-third;
  }
}

.cellLast {
  width: $smallWidth-last;
  padding: 0;
  display: flex;
  justify-content: space-between;

  @media (min-width: 400px) {
    width: $mediumWidth-last;
    padding: 0;
  }

  @media (min-width: 640px) {
    width: $width-last;
    padding: 10px 30px 10px 10px;
  }
}

.rowUpload {
  min-height: 70px;
  font-family: $primary-font;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #fff;
  cursor: pointer;
  background-color: #4f8cf5;
  border: 2px dashed #4f8cf5;

  @media (min-width: 640px) {
    font-size: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  input {
    display: none;
  }
}

.dropBox {
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
}

.dropBoxWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dragActive {
  border: 2px dashed #fff;
}

.loaderHolder {
  @include table-row;

  justify-content: center;
  position: relative;
  min-height: 74px;
}
