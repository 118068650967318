.container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.containerAdmin {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.containerIconAdmin {
  margin: 0 10px;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  font-weight: 700;
}

.containerIcon {
  padding: 10px;
  margin-top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
}

.send {
  color: #969696;
  margin: 0;
  font-size: 10px;
}

.sendAdmin {
  color: #969696;
  font-size: 10px;
  padding-bottom: 2px;
}
