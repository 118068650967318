.popoverHelper {
  z-index: 1200;
  position: fixed;
  width: 275px;
}

.popoverHelper .ant-popover-inner {
  box-shadow: none;
}

.popoverHelper .ant-popover-inner-content {
  max-height: 300px;
  overflow: auto;
  padding: 0;
}

.popoverHelper .ant-list-item-meta {
  cursor: pointer;
}

.ant-popover-inner-content::-webkit-scrollbar {
  width: 5px;
}

.ant-popover-inner-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.ant-popover-inner-content::-webkit-scrollbar-thumb {
  background: bisque;
  border-radius: 10px;
}

.ant-popover-inner-content::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
