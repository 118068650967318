.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6.5px;
  position: absolute;
  bottom: 46px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.containerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btnMedia {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #f54343 !important;
  border: 2px solid #f54343 !important;
  border-radius: 100% !important;
}

.btnMedia:hover {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #f54343 !important;
  border: 2px solid #f54343 !important;
  border-radius: 100% !important;
}

.btnMedia:focus {
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  align-items: center !important;
  justify-content: center;
  background-color: #f54343 !important;
  border: 2px solid #f54343 !important;
  border-radius: 100% !important;
}

.btnMedia img {
  width: 21px;
  height: 21px;
}

.btnMedia button:disabled {
  border: none !important;
  background: transparent !important;
}

.btnMediaActive {
  background-color: transparent !important;
  border: 2px solid white !important;
}

.btnMediaActive:hover {
  background-color: transparent !important;
  border: 2px solid white !important;
}

.btnMediaActive:focus {
  background-color: transparent !important;
  border: 2px solid white !important;
}

.containerTexts {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}

.label {
  margin: 0;
  font-size: 12px;
}

.status {
  margin: 0;
  font-weight: bold;
  font-size: 13px;
}

.drawer {
  padding: 0;
  padding-right: 20;
  padding-left: 20;
  display: "flex";
  flex-direction: "column";
  align-items: "center";
}

@media only screen and (max-device-width: 480px) {
  .container {
    border: none;
    height: auto;
    bottom: 15px;
    width: 100%;
    border-radius: 0;
  }
}

@media only screen and (max-height: 700px) {
  .container {
    bottom: 20px;
  }
}

@media only screen and (max-height: 650px) {
  .container {
    transform: none;
    position: relative;
    bottom: 0;
    left: 0;
    top: 5px;
  }
}

@media only screen and (max-height: 375px) {
  .container {
    bottom: 20px;
  }
}
