.loader {
  width: 100px;
}

.loader-dots {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-dot {
  margin: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #c2cdd2;
  transform: scale(0.5);
  animation: scale-dot 1.2s ease-out infinite;
}
.loader-dot:nth-of-type(2) { animation-delay: 0.2s; }
.loader-dot:nth-of-type(3) { animation-delay: 0.3s; }
.loader-dot:nth-of-type(4) { animation-delay: 0.4s; }
.loader-dot:nth-of-type(5) { animation-delay: 0.5s; }

@keyframes scale-dot {
  0% {
    transform: scale(0.5);
  }

  25% {
    transform: scale(1);
  }

  75% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(0.5);
  }
}
