.container {
  position: fixed;
  width: 100vw;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 1s ease;
}

.card {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  text-align: center;

  .cardBody {
    padding: 2rem;

    p {
      font-size: 16px;
    }
  }
}

.btn {
  margin: 1rem 0;
}
