.container {
  height: 80px;
  border-bottom: 1px solid #c2c2c2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
}

.container p {
  margin: 0;
  margin-left: 15px;
  font-weight: 600;
}
