.react-calendar {
  width: 269px;
  border: none;
  margin-top: 6px;
}

.react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from {
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  font-weight: 500;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none !important;
}

.react-calendar__navigation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

.react-calendar__month-view__days__day--weekend {
  color: #bfbfbf;
}

.react-calendar__tile--active {
  background-color: #22a7e7;
}

.ant-select-dropdown {
  z-index: 1300;
}
