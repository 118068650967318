.login-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 1s ease;
}

.login-card {
  max-width: 90%;
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
}

.login-card .ant-card-body {
  padding: 3rem;
}

.login-logo {
  width: 90%;
  max-width: 150px;
  margin-bottom: 25px;
}
