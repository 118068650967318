.container {
  width: 100vw;
  height: calc(100vh - 100px);
  background-color: #2d2d2d;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  .mediaPlayer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    video {
      object-fit: contain !important;
    }
  }

  .containerStatusSmall {
    background-color: #565656;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-sizing: border-box;
    margin-top: 10px;
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 10px;

    p {
      color: white;
      margin: 0;
      font-size: 14px;
    }
  }

  .fullUser {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 970px) {
      .containerStatusSmall {
        display: none;
      }
    }
  }

  .usersContainer {
    width: 250px;
    position: absolute;
    bottom: 5px;
    right: 5px;

    @media (max-width: 970px) {
      bottom: 80px;

      .containerStatusSmall {
        p {
          display: none;
        }

        padding-left: 5px;
      }
    }

    @media (max-width: 480px) {
      width: 150px;
    }

    .user {
      height: 200px;

      @media (max-width: 480px) {
        height: 200px;
      }
    }
  }
}
