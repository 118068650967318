@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LatoBold';
  src: url("./fonts/Lato-Bold.ttf") format("ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'LatoLight';
  src: url("./fonts/Lato-Light.ttf") format("ttf");
  font-weight: lighter;
  font-style: normal;
}
