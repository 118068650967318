
.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: bisque;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #1890ff;
}
