.btn {
  width: 50px;
  height: 50px;
  background-color: #22a7e7;
  box-shadow: 1px 4px 14px 0 rgba(0, 0, 0, 0.15);
}

.btn svg {
  fill: white;
}

.btn:hover {
  background-color: #22a7e7 !important;
}

.btn:focus {
  background-color: #22a7e7 !important;
}

.btn span {
  font-size: 23px;
}
