.status {
  color: #008000;
  padding: 0 1rem;
}

.fieldError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
