@mixin table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: $grey-700;

  @media (min-width: 640px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@mixin table-header-col {
  padding: 16px 6px;
  font-family: $primary-font;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  color: $white;

  @media (min-width: 400px) {
    font-size: 11px;
  }

  @media (min-width: 640px) {
    padding: 24px 10px 22px;
  }
}

@mixin table-row {
  display: flex;
  align-items: center;
  border-left: 1px solid $grey-400;
  border-right: 1px solid $grey-400;
  border-bottom: 1px solid $grey-400;
}

@mixin table-cell {
  padding: 20px 10px;
  font-family: $primary-font;
  font-size: 9px;
  line-height: 1.4;
  letter-spacing: 0.2px;
  font-weight: normal;
  color: $grey-800;
  word-break: break-word;

  @media (min-width: 400px) {
    font-size: 11px;
  }
}
