@import url('../../styles//css//colors.css');

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.subText {
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: var(--gray800);
}

.referLink {
  color: black;
}

.referLink:hover {
  color: inherit;
  text-decoration: underline;
}

.editIcon {
  font-size: 16px;
  margin-left: 4px;
  margin-top: -5px;
}

.editIcon span {
  color: var(--lightBlue);
  cursor: pointer;
  margin-left: 4px;
}

.editIcon span:hover {
  color: var(--blue);
}

.containerMust {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}

.status {
  color: #008000;
  padding: 0 1rem;
}

.copiedOverlay {
  position: absolute;
  top: 50%;
  background: rgba(168, 168, 168, 0.9);
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  width: 111px;
  height: 0;
  color: white;
  text-align: center;
  opacity: 0;
  transition: opacity 0.15s ease-out;
}

.copiedOverlay p {
  font-size: 18px;
  font-weight: bold;
}

.selectContactsContainer {
  height: calc(40vh + 60px);
  overflow: scroll;
  overflow-x: visible;
  padding: 0 5px;
  border: 1px solid #3d80f3;
}

.selectAllBar {
  background-color: #3d80f3;
  padding: 5px 10px;
}

.referredCount {
  font-size: 14px;
  line-height: 32px;
  display: flex;
}

.referredCount button {
  padding: 0;
}

.referredCount button:hover {
  text-decoration: underline !important;
}
