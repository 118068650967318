:root {
  --white: #fff;
  --lightBlue: #78bffd;
  --mattBlue: #87bbcf;
  --blue: #3d80f3;
  --darkBlue: #001def;
  --orange: #fdcb5c;
  --green: #81ddb1;
  --black: #000;
  --red: #ff4316;
  --lightRed: #ff6e5a;
  --gray100: #f9f9f9;
  --gray200: #f0f0f0;
  --gray300: #f6f8f9;
  --gray400: #d8d8d8;
  --gray500: #c2cdd2;
  --gray600: #616161;
  --gray700: #676e71;
  --gray800: #4c4c4c;
  --gray900: #9f9f9f;
}
