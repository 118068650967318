.container {
  background-color: #f4f6f9;
  width: 317px;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
}

.title {
  color: #0a64b2;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.noEventTypes {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5em;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

.containerStep1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.containerStep1 button {
  border: 1px solid #22a7e7;
  border-radius: 2px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: normal !important;
  width: 125px;
  margin-top: 18px;
  background-color: transparent;
  color: #22a7e7;
  font-size: 14px;
}

.containerStep2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.containerStep2 button {
  border-radius: 2px;
  height: 40px;
  width: 110px;
  background-color: #22a7e7;
  color: #fff;
  font-size: 14px;
  margin: 15px;
}

.textArea {
  border-radius: 0.5rem;
  border-color: #aeaeae;
  background: none;
  width: 80%;
}

.skip {
  cursor: pointer;
  margin: 0.5em 0;
  color: #22a7e7;
  font-size: 16px;
  font-weight: 500;
}

.skipDisabled {
  margin: 0.5em 0;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  font-size: 16px;
  font-weight: 500;
}

.containerStep3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.containerStep4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
  width: 100%;
}

.contOpts {
  width: 90%;
  margin-top: 6px;
}

.contOpts button {
  border: 1px solid #22a7e7;
  border-radius: 2px;
  height: 40px;
  width: 84px;
  background-color: transparent;
  color: #22a7e7;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  float: left;
}

.containerStep5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.containerStep5 button {
  border-radius: 2px;
  height: 40px;
  min-width: 110px;
  background-color: #22a7e7;
  color: #fff;
  font-size: 14px;
  margin-top: 14px;
}

.error svg {
  fill: #c41a1a !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: 14px !important;
}

.success svg {
  fill: #52c41a !important;
  width: 60px !important;
  height: 60px !important;
  margin-top: 14px !important;
}

.successtxt {
  color: #565656 !important;
  font-size: 14px !important;
  font-weight: 500px !important;
  margin-top: 8px !important;
}

.contLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.contLabel p {
  color: #565656;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
}

.contLabel svg {
  fill: #565656;
  width: 16px;
  height: 16px;
}

.left {
  width: 16px;
  height: 16px;
  fill: #565656;
  position: absolute;
  top: 23px;
  left: 15px;
}

.right {
  width: 16px;
  height: 16px;
  fill: #565656;
  position: absolute;
  top: 15px;
  right: 15px;
}
