.messages {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0;
  background-color: #f4f6f9;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  color: #565656;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  max-width: 70%;
  text-align: left;
}

.messageSms {
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: 0;
  background-color: #ffa50026;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  color: #565656;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  max-width: 70%;
}

.sms {
  background-color: #ffa50026;
}

.email {
  background-color: #f4f6f9;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  position: relative;
}

.yours .messageSms {
  margin-right: 25%;
  position: relative;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  margin-left: 25%;
  background-attachment: fixed;
  position: relative;
}

.mine .messageSms {
  margin-left: 25%;
  background-attachment: fixed;
  position: relative;
}

.send {
  color: #969696;
  margin: 0;
  margin-top: 10px;
  font-size: 10px;
}
