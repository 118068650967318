.container {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  box-sizing: border-box;
  height: 330px;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  border-bottom: 1px solid #cfd4d4;
}

.container::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
