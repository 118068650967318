.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  max-width: 75%;
}

.name {
  margin: 0;
  color: #0a64b2;
  font-size: 20px;
  font-weight: 500;
}

.continfo p {
  color: #969696;
  margin: 0;
  margin-left: 15px;
  font-size: 12px;
}

.badge {
  margin-left: 10px;
}

.badge sup {
  margin-top: 47px;
  margin-right: 3px;
  width: 10px;
  height: 10px;
}

.continfo {
  padding: 0 10px;
  max-width: inherit;
}
