.container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.badge p {
  color: white !important;
}

.containerLoad {
  width: 360px;
  display: none;
  flex-direction: column;
  align-items: center;
  max-height: 631px;
  border-radius: 4px;
  margin-bottom: 14px;
  background-color: #fff;
  box-shadow: 0 2px 13px 0 rgba(165, 188, 222, 0.5);
  padding: 20px;
}

.visible {
  display: flex;
  transition: visibility 0s, opacity 0.5s linear;
}
