.switch {
  & input {
    & ~ label {
      &::before {
        content: "Off";
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 2rem;
        min-width: 3.5rem;
        padding: 4px;
      }

      &::after {
        min-height: calc(2rem - 4px);
      }
    }

    &:checked ~ label::after {
      transform: translateX(2.5rem) !important;
    }

    &:checked ~ label::before {
      content: "On";
    }
  }
}

.status {
  color: #008000;
  padding: 10px 0;
}

.fieldError {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.container {
  display: flex;
  align-items: center;
}

.containerMust {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}
