.key {
  line-height: 18px;
  margin: 7px 0;
  display: flex;
}

.keyTitle {
  font-size: 16px;
  color: var(--gray800);
  font-weight: bolder;
  font-family: "LatoBold", sans-serif;
  padding-right: 7px;
  min-width: 85px;
  text-align: right;
}

.value {
  font-size: 15px;
  color: var(--gray800);
  font-family: "LatoLight", sans-serif;
  letter-spacing: 0.2px;
}

.join {
  font-family: "LatoBold", sans-serif;
  display: block;
  font-size: 16px;
  line-height: 28px;
  padding: 6px;
  letter-spacing: 0.6px;
  text-align: center;
  border-radius: 6px;
  outline: none;
  border: none;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--gray100);
  margin: 0 auto;
  width: 100px;
  margin-top: 21px;
}

.join:hover {
  color: var(--gray100);
  background-color: var(--lightBlue);
}

.cancel {
  color: var(--gray100);
  background-color: var(--red);
}

.btnBlock {
  display: "flex";
  justify-content: "space-between";
  margin-bottom: "10px";
}
