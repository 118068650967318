@import url("../../../styles/css/colors.css");

.upcomingAppointments--container {
  display: block;
  min-width: 300px;
  margin: 0 auto;
  font-family: 'Lato', sans-serif;
  margin-top: 25px;
}

@media (min-width: 640px) {
  .upcomingAppointments--container {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .upcomingAppointments--container {
    padding: 0 20px;
  }
}

@media (min-width: 1024px) {
  .upcomingAppointments--container {
    padding: 0 40px;
    max-width: 1220px;
  }
}
