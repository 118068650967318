
.InviteForm {
  padding: 1rem 0;
  padding-right: 2rem;

  .ant-form-item-control {
    padding-left: 1rem;
  }
}

.InviteForm__select {
  width: 100%;
  display: flex;
  align-items: center;
}

.InviteForm__submit {
  display: flex;
  justify-content: center;
  padding-top: 30px;

  button {
    width: 150px;
  }
}

.twoRowsRequired label {
  white-space: normal;
  margin-right: 12px;
  display: inline-block;
}

.twoRowsRequired label::before {
  margin-top: -20px;
  margin-left: 11px;
}
