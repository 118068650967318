.container {
  background-color: #f4f6f9;
  height: 262px;
  width: 90%;
  margin: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 0;
  color: #0a64b2;
  font-size: 20px;
  font-weight: 600;
}

.btntext {
  margin: 0;
  color: #a1a1a1;
  font-size: 10px;
  margin-top: 12px;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 36px;
}

.containerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 21px;
}

.containerButtonRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 21px;
}

.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn svg {
  transform: rotate(224deg);
}
