.container {
  width: 366px;
  display: none;
  flex-direction: column;
  align-items: center;
  max-height: 631px;
  border-radius: 4px;
  margin-bottom: 14px;
  background-color: #fff;
  box-shadow: 0 2px 13px 0 rgba(165, 188, 222, 0.5);
}

.visible {
  display: flex;
  transition: visibility 0s, opacity 0.5s linear;
}

.blur {
  filter: blur(1px);
  transition: visibility 0s, opacity 1s linear;
}
