.btn {
  margin-right: 5px;
  background: transparent !important;
  border: none !important;
  width: 20px !important;
  height: 30px !important;
}

.btn svg {
  width: 20px;
  height: 20px;
}
