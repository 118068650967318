.field {
  background: #ffff0040;
  position: absolute;
  z-index: 99;
  border: 2px dashed #94946c;
}

.field:hover {
  background: #ffff0085;
}

.completed-field {
  background: #00ff0085;
  border: 1px solid #0f0;
}

.completed-field:hover {
  background: #00ff0085;
}
