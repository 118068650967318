.master {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  background-color: #fff;
  margin: 2rem;
  padding: 1rem;
  width: 65%;
}

.head {
  text-align: center;
  border-bottom: #444;
  margin-bottom: 0;
}

.content {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.left {
  width: 70%;
}

.rigth {
  width: 30%;
}

@media (max-width: 1100px) {
  .content {
    flex-direction: column;
  }

  .left,
  .rigth {
    width: 100%;
  }
}

.Invite__sideLabelSignIn {
  font-size: 15px;
  font-weight: bold;
}

.Invite__sideDescription {
  font-size: 15px;
  text-align: left;
}

@media (max-width: 481px) {
  .container {
    width: 75%;
  }
}
