.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  margin-top: 0;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 11px;
}

.messageMine {
  align-items: flex-end;
}

.userInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userInfoMine {
  justify-content: flex-end;
}

.name {
  color: #0a64b2;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-left: 9px;
}

.nameMine {
  margin-left: 0;
  margin-right: 9px;
}

.date {
  margin: 0;
  margin-top: 5px;
  color: #969696;
  font-size: 10px;
}

.text {
  background-color: #f4f6f9;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  margin-top: 10px;
}

.textMine {
  text-align: end;
}

.containerText {
  display: flex;
  flex-direction: column;
}
