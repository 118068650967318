@import "./components/header.scss";
@import "./components/table.scss";

/* Colors */
$white: #fff;
$lightBlue: #78bffd;
$mattBlue: #87bbcf;
$blue: #3d80f3;
$darkBlue: #001def;
$orange: #fdcb5c;
$green: #81ddb1;
$black: #000;
$red: #ff4316;
$lightRed: #ff5f37;

$grey-100: #f9f9f9;
$grey-200: #f0f0f0;
$grey-300: #f6f8f9;
$grey-400: #d8d8d8;
$grey-500: #c2cdd2;
$grey-600: #616161;
$grey-700: #676e71;
$grey-800: #4c4c4c;
$grey-900: #9f9f9f;

/* Fonst */
$primary-font: "Lato", sans-serif;

@mixin text-regular {
  font-family: $primary-font;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #000;
}
