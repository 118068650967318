.container {
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 13px;
  padding-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.containerUserInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.closeBtn {
  width: 20px;
  height: 20px;
  background: transparent !important;
  border: none !important;
}

.closeBtn svg {
  width: 20px;
  height: 20px;
}
