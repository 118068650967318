.phoneContainer {
  width: 100%;
}

.phoneContainer input {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
  padding-left: 10px;
  height: 32px;
}

.phoneContainer input:focus {
  color: #000;
  border: 1px solid #40a9ff;
  border-right-width: 1px;
  outline: none;
  box-shadow: 0 0 0 2px #1890ff33;
}
