:root {
  --padding: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.containerButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.columninfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: rgba(86, 86, 86, 0.59);
}

.rowOff {
  background-color: #2d2d2d;
}

.title {
  font-weight: 490 !important;
  color: #fff !important;
  font-size: 48px !important;
  margin-bottom: 5px !important;
  padding: var(--padding);
}

.description {
  font-weight: 425 !important;
  color: #fff !important;
  font-size: 18px !important;
  padding: var(--padding);
}

.image {
  margin-bottom: 2rem;
}

.join {
  margin-top: 20px;
  background-color: #22a7e7 !important;
  width: 110px !important;
  height: 40px !important;
  color: white !important;
  font-size: 14px !important;
  border-radius: 2px !important;
}

.webcam {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: calc(100vh - 100px);
}

@media only screen and (max-device-width: 480px) {
  .columninfo {
    margin-top: 0;
  }

  .title {
    font-size: 32px !important;
    width: 90%;
    text-align: center;
    padding: 10px;
  }

  .image {
    margin: 0;
  }

  .description {
    width: 90% !important;
    text-align: center;
    padding: 10px;
  }

  .join {
    margin: 10px;
  }

  .row {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(86, 86, 86, 0.59);
  }

  .webcam {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .rowOff {
    background-color: #2d2d2d;
  }
}

@media screen and (orientation: landscape) and (min-device-width: 319px) and (max-device-width: 850px) {
  .columninfo {
    margin-top: 0;
  }

  .title {
    font-size: 30px !important;
    width: 90%;
    text-align: center;
    padding: var(--padding);
  }

  .description {
    width: 90% !important;
    text-align: center;
    padding: var(--padding);
  }

  .join {
    margin-right: inherit;
    margin-left: inherit;
    margin-bottom: 40px;
  }

  .row {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(86, 86, 86, 0.59);
  }

  .webcam {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .rowOff {
    background-color: #2d2d2d;
  }

  .ava {
    display: none;
  }
}

@media only screen and (max-height: 375px) {
  .join {
    padding: 0 0 !important;
    margin: 0 0 70px !important;
  }

  .title,
  .description {
    padding-bottom: 0 !important;
  }
}

@media (min-device-width: 540px) and (max-device-width: 720px) {
  .title {
    font-size: 34px !important;
    width: 90%;
    text-align: center;
    padding: 10px;
  }

  .description {
    width: 90% !important;
    text-align: center;
    padding: 10px;
  }
}
