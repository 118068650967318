.container {
  display: flex;
  align-items: center;
}

.containerMust {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
}

.status {
  color: #008000;
  padding: 0 1rem;
}
