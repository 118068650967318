@import url("../../../styles/css/colors.css");

.card {
  margin: 0;
  padding: 35px 20px;
  width: 100%;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 15px;
}

@media all and (min-width: 640px) {
  .card {
    padding: 35px 40px;
  }
}

@media all and (min-width: 768px) {
  .card {
    padding: 40px 54px;
  }
}

@media all and (min-width: 640px) {
  .card {
    margin-bottom: 20px;
  }
}

.title {
  font-size: 24px;
  letter-spacing: 0.8px;
  color: var(--grey800);
  display: block;
  padding-bottom: 20px;
  margin-bottom: 30px;
  position: relative;
}

.title::before {
  content: '';
  position: absolute;
  border-bottom: 1px solid var(--gray200);
  bottom: 0;
  left: -60px;
  right: -60px;
  background-color: blueviolet;
}

.appointmentsListContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.appointment {
  border: 1px solid var(--gray200);
  border-radius: 6px;
  padding: 15px;
  margin: 0 7px 15px;
  background-color: var(--gray300);
  min-width: 24em;
}

@media all and (max-width: 600px) {
  .appointmentsListContainer {
    justify-content: center;
  }

  .appointment {
    width: 90%;
  }
}

@media all and (min-width: 600px) {
  .appointment {
    width: 45%;
  }
}

@media all and (min-width: 900px) {
  .appointment {
    width: 30%;
  }
}

.key {
  line-height: 18px;
  margin: 7px 0;
  display: flex;
}

.keyTitle {
  font-size: 16px;
  color: var(--gray800);
  font-weight: bolder;
  font-family: 'LatoBold', sans-serif;
  padding-right: 7px;
  min-width: 85px;
  text-align: right;
}

.value {
  font-size: 15px;
  color: var(--gray800);
  font-family: 'LatoLight', sans-serif;
  letter-spacing: 0.2px;
}

.valueNotes {
  font-size: 15px;
  color: var(--gray800);
  font-family: 'LatoLight', sans-serif;
  letter-spacing: 0.2px;
  height: 6em;
  overflow-y: auto;
}

.join {
  font-family: 'LatoBold', sans-serif;

  /* display: block; */
  font-size: 16px;
  line-height: 28px;
  padding: 6px;
  letter-spacing: 0.6px;
  text-align: center;
  border-radius: 6px;
  outline: none;
  border: none;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  background-color: var(--blue);
  color: var(--gray100);
  margin: 0 auto;
  width: 100px;
  margin-top: 21px;
}

.join:hover {
  color: var(--gray100);
  background-color: var(--lightBlue);
}

.cancel {
  font-family: 'LatoBold', sans-serif;

  /* display: block; */
  font-size: 16px;

  /* line-height: 28px;
  padding: 6px;  */
  letter-spacing: 0.6px;
  text-align: center;
  outline: none;
  border: none;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  color: var(--red);
  margin: 0 auto;
  width: 100px;
  margin-top: 25px;
}

.cancel:hover {
  color: var(--lightRed);
}

.btnBlock {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: "10px";
}
